import type React from 'react';

type ContainerProps = {
  className?: string;
  children: React.ReactNode;
};

export const Container = (props: ContainerProps) => {
  const {children, className = ''} = props;
  return <div className={`mx-auto w-full px-4 sm:px-8 lg:px-16 2xl:w-10/12 ${className}`}>{children}</div>;
};
